import * as React from 'react'
import './layout.scss';
import Header from './header';
import { createTheme, ThemeProvider } from '@mui/material';
import Footer from './footer';
import { Helmet } from 'react-helmet';
import { useSiteMetadata } from '../hooks/use-site-metadata';
import { useLocation } from '@reach/router';

const theme = createTheme({
    typography: {
        fontFamily: 'Noto Sans, Arial, sans-serif'
    },
    palette: {
        primary: {
            main: '#83b2d5',
            contrastText: '#fff',
        },
        secondary: {
            main: '#fff',
            dark: '#eee'
        }
    },
})

export default function Layout({ children }) {
    const { title, description, siteUrl } = useSiteMetadata();
    const { pathname } = useLocation();
    const canonical = `${siteUrl}${pathname}`;

    return <ThemeProvider theme={theme}>
        <Helmet>
            <meta charSet="utf-8"/>
            <meta name="description" content={description}/>
            <title>{title}</title>
            <meta name="robots" content="index,follow"/>

            <link rel="shortcut icon" href="/images/favicons/favicon.ico"/>
            <link rel="icon" type="image/png" sizes="192x192" href="/images/favicons/favicon_192.png"/>

            <link rel="canonical" href={canonical}/>

            <meta property="og:title" content={title}/>
            <meta property="og:url" content={canonical}/>
            <meta property="og:description" content={description}/>
            <meta property="og:image" content={`${siteUrl}/images/og_cover.jpg`}/>
            <meta property="og:type" content="website"/>

            <meta name="twitter:card" content="summary"/>
            <meta name="twitter:site" content="@WorldResources"/>
            <meta name="twitter:creator" content="@WorldResources"/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:url" content={canonical}/>
            <meta name="twitter:description"
                  content={description}/>
            <meta name="twitter:image"
                  content={`${siteUrl}/images/twitter_card.jpg`}/>
        </Helmet>
        <Header/>
        <div className="site-layout">{children}</div>
        <Footer/>
    </ThemeProvider>
}