import { graphql, Link, useStaticQuery } from 'gatsby';
import * as React from 'react';
import './footer.scss';
import ExternalLink from './controls/ExternalLink';

function FooterPartner({ partner }) {
    return <div className="partner">
        <ExternalLink url={partner.url} className="partner-link">{partner.name}</ExternalLink>
        <div className="social">
            {partner.social.map(item => <ExternalLink key={item.service} url={item.url}><img
                src={`/images/social/${item.service}.svg`}/></ExternalLink>)}
        </div>
    </div>
}

export default function Footer() {
    const data = useStaticQuery(graphql`
        query FooterQuery {
          footer {
              developed_by
              google_earth_engine {
                name
                url
                social {
                  service
                  url
                }
              }
              wri {
                name
                url
                social {
                  service
                  url
                }
              }
          }
        }
    `).footer;

    return <div className="bottom-footer">
        <div className="wrapper">
            <div className="footer-left">
                <div className="site-name">Dynamic World</div>

                <nav>
                    <ul>
                        <li>
                            <Link to="/explore">Explore the data</Link>
                        </li>
                        <li>
                            <Link to="/about">About</Link>
                        </li>
                    </ul>
                </nav>

                <div className="developed-by">{data.developed_by}</div>
                <div className="graphicacy">
                    <ExternalLink url="https://graphicacy.com/">
                        <img src="/images/built_by_graphicacy.svg" />
                    </ExternalLink>
                </div>
            </div>
            <div className="footer-right">
                <FooterPartner partner={data.google_earth_engine}/>
                <FooterPartner partner={data.wri}/>
            </div>
        </div>
    </div>
}